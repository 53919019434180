import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'
function getTotalData (data, fertilizerId) {
  let total = 0
  data.forEach((element, key) => {
    total += (typeof element['fertlizer_amount_' + fertilizerId] !== 'undefined') ? element['fertlizer_amount_' + fertilizerId] : 0
  })
  return total
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, search, fertilizerNameList) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = [
          [
            { text: vm.$t('fertilizerSales.allocationType'), style: 'search', alignment: 'right' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: search.allo, style: 'search', alignment: 'left' }
          ],
          [
            { text: vm.$t('fertilizerSales.allocationMonth'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: search.monthList, alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('fertilizerSales.allocationYear'), style: 'search', alignment: 'right' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: search.YearList, style: 'search', alignment: 'left' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['47%', '6%', '47%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center' },
            { text: vm.$t('globalTrans.district'), style: 'th', rowSpan: 2, alignment: 'center' },
            { text: vm.$t('fertilizerReport.ferti_name_quantity'), style: 'th', colSpan: 3, alignment: 'center' },
            {},
            {}
          ]
        ]
        const headList = [
          {},
          {}
        ]
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        allRows.push(headList)
        data.map((report, index) => {
          const newRow = [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? report.district_name_bn : report.district_name, style: 'td', alignment: 'center' }
          ]
          fertilizerNameList.map((fertilizer, index) => {
            const val = (typeof report['fertlizer_amount_' + fertilizer.value] !== 'undefined') ? vm.$n(report['fertlizer_amount_' + fertilizer.value]) : 0
            newRow.push(
              { text: vm.$t(val), style: 'th', alignment: 'right' }
            )
          })
          allRows.push(newRow)
        })
        const footList = [
          { text: vm.$t('fertilizerReport.GrandTotalMTon'), style: 'th', colSpan: 2, alignment: 'right', bold: true },
          {}
        ]
        fertilizerNameList.map((item, index) => {
          const total = getTotalData(data, item.value)
          footList.push(
            { text: vm.$n(total), style: 'th', alignment: 'right', bold: true }
          )
        })
        allRows.push(footList)
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['10%', '30%', '20%', '20%', '20%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
            fontSize: 10,
            margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 15, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('Ministry Allocation Report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
