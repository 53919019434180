<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.ministry_allocation_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="month_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('fertilizerSales.month')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.month"
                      :options="monthList"
                      id="month_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="allocation_type_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.allocation_type_id"
                      :options="allocationTypeList"
                      id="allocation_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Allocation Type"  vid="fertilizer_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fertilizer_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('fertilizerConfig.fertilizer_name')}}  <span class="text-danger"></span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.fertilizer_id"
                      :options="fertilizerNameList"
                      id="fertilizer_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
                </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.ministry_allocation_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row v-show="!loading">
                  <b-col>
                      <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                        {{ $t('fertilizerReport.ministry_allocation_report')}}
                      </list-report-head>
                  </b-col>
                </b-row>
                <div class="text-center">
                  <table style="width:100%;color:black;">
                    <tr>
                      <td align="right" style="width:40%">{{$t('fertilizerSales.allocationType')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">{{ allocationTypeList.filter(item => item.value === search.allocation_type_id)[0] ? allocationTypeList.filter(item => item.value === search.allocation_type_id)[0].text : '' }}</td>
                    </tr>
                    <tr>
                      <td align="right" style="width:40%">{{$t('fertilizerSales.allocationMonth')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">{{ monthList.filter(item => item.value === search.month)[0] ? monthList.filter(item => item.value === search.month)[0].text : '' }}</td>
                    </tr>
                    <tr>
                      <td align="right" style="width:40%">{{$t('fertilizerSales.allocationYear')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">{{ fiscalYearList.filter(item => item.value === search.fiscal_year_id)[0] ? fiscalYearList.filter(item => item.value === search.fiscal_year_id)[0].text : '' }}</td>
                    </tr>
                  </table>
                </div>
                <b-row>
                  <div class="col text-right">
                    <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                  </div>
                </b-row>
                <b-row>
                  <b-col md="12" class="table-responsive">
                    <b-overlay>
                      <b-table-simple bordered hover small caption-top responsive>
                          <b-thead>
                            <b-tr>
                              <b-th rowspan="2" style="text-align:center;" class="align-middle">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th rowspan='2' style="text-align:center" class="align-middle">{{ $t('globalTrans.district') }}</b-th>
                              <b-th colspan="100%" scope="colgroup" style="text-align: center"> {{ $t('fertilizerReport.ferti_name_quantity') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th style="text-align:right" v-for="(sale,i) in fertilizerNameList" :key="i">
                                {{ ($i18n.locale === 'bn') ? sale.text_bn : sale.text_en }}
                              </b-th>
                            </b-tr>
                            <b-tr v-for="(fertilizerData, index) in allocationData" :key="index">
                                <td scope="col"  style="text-align:center">{{ $n(index+1) }}</td>
                                <td scope="col"  style="text-align:center">
                                  {{ ($i18n.locale === 'bn') ? fertilizerData.district_name_bn : fertilizerData.district_name }}
                                </td>
                                <td  style="text-align:right" v-for="(fertilizer,i) in fertilizerNameList" :key="i">
                                  {{ (typeof fertilizerData['fertlizer_amount_'+fertilizer.value] !== 'undefined') ? $n(fertilizerData['fertlizer_amount_'+fertilizer.value]) : 0 }}
                                </td>
                            </b-tr>
                            <!--   {{ item['fertilizer_amount_'+fertilizer_id] !== 'undefined' ? item['fertilizer_amount_'+fertilizer_id] : 0 }} -->
                          </b-thead>
                          <b-tr>
                            <b-th colspan="2" style="text-align:right">{{ $t('fertilizerReport.grand_total') }}</b-th>
                            <b-th style="text-align:right" v-for="(sale,i) in fertilizerNameList" :key="'last' + i">
                              {{ $n(getTotalData(allocationData, sale.value))}}
                            </b-th>
                          </b-tr>
                      </b-table-simple>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, ministryAllocationApi } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import ListReportHead from '.././ListReportHead.vue'

export default {
  name: 'UiDataTable',
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      regionTable: false,
      withFerlizer: false,
      withoutFerlizer: false,
      search: {
        fiscal_year_id: 0,
        month: 0,
        allocation_type_id: 0,
        org_id: 3,
        monthList: '',
        allo: '',
        fertilizer_id: 0
      },
      allocationData: [],
      organization: [],
      tsp: 0,
      mop: 0,
      dap: 0,
      testId: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    formTitle () {
       return this.$t('fertilizerReport.ministry_allocation_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerConfig.description'), class: 'text-left' }
        ]
        let keys = []
        keys = [
          { key: 'index' }
        ]
        this.fertilizerNameList.filter(item => {
          const labelItem = {
            label: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en,
            class: 'text-left'
          }
          labels.push(labelItem)
          keys.push({ key: 'fertilizer_amount_' + item.value })
        })
        labels.push({ label: this.$t('fertilizerReport.total'), class: 'text-left' })
        keys.push({ key: 'total_amount' })

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    godownColumn () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerReport.godownName'), class: 'text-left' }
        ]
        let keys = []
        keys = [
          { key: 'index' }
        ]
        keys.push({ key: 'godownName' })
        this.fertilizerNameList.filter(item => {
          const labelItem = {
            label: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en,
            class: 'text-left'
          }
          labels.push(labelItem)
          keys.push({ key: 'fertilizer_amount_' + item.value })
        })
        labels.push({ label: this.$t('fertilizerReport.total'), class: 'text-left' })
        keys.push({ key: 'total_amount' })

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    regionColumn () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerReport.region'), class: 'text-left' }
        ]
        let keys = []
        keys = [
          { key: 'index' }
        ]
        keys.push({ key: 'godownName' })
        this.fertilizerNameList.filter(item => {
          const labelItem = {
            label: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en,
            class: 'text-left'
          }
          labels.push(labelItem)
          keys.push({ key: 'fertilizer_amount_' + item.value })
        })
        labels.push({ label: this.$t('fertilizerReport.total'), class: 'text-left' })
        keys.push({ key: 'total_amount' })

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList
    },
    fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getTotalData (data, fertilizerId) {
      let total = 0
      data.forEach((element, key) => {
        total += (typeof element['fertlizer_amount_' + fertilizerId] !== 'undefined') ? element['fertlizer_amount_' + fertilizerId] : 0
      })
      return total
    },
    getAllMovement (yyy) {
      return parseFloat('.fertlizer_amount_' + yyy)
    },
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.search.allo = this.allocationTypeList.filter(item => item.value === this.search.allocation_type_id)[0] ? this.allocationTypeList.filter(item => item.value === this.search.allocation_type_id)[0].text : ''
      this.search.monthList = this.monthList.filter(item => item.value === this.search.month)[0] ? this.monthList.filter(item => item.value === this.search.month)[0].text : ''
      this.search.YearList = this.fiscalYearList.filter(item => item.value === this.search.fiscal_year_id)[0] ? this.fiscalYearList.filter(item => item.value === this.search.fiscal_year_id)[0].text : ''
      this.showData = true
      this.loadData()
      this.withFerlizer = true
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, ministryAllocationApi, params).then(response => {
        if (response.success) {
          this.allocationData = this.customiseData(response.data)
           let tsp = 0
           let mop = 0
           let dap = 0
            this.allocationData.forEach((element, key) => {
                tsp += element.fertlizer_amount_1 !== undefined ? element.fertlizer_amount_1 : 0
                mop += element.fertlizer_amount_2 !== undefined ? element.fertlizer_amount_2 : 0
                dap += element.fertlizer_amount_3 !== undefined ? element.fertlizer_amount_3 : 0
            })
            this.tsp = tsp
            this.mop = mop
            this.dap = dap
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    customiseData (data) {
      const resultData = []
      for (const districtId in data) {
        const item = {}
        const district = this.districtList.find(item => item.value === parseInt(districtId))
        if (typeof district !== 'undefined') {
          item.district_name = district.text_en
          item.district_name_bn = district.text_bn
          const fertilizerData = data[districtId]
          for (const fertilizerId in fertilizerData) {
            item['fertlizer_amount_' + fertilizerId] = fertilizerData[fertilizerId]
          }
          resultData.push(item)
        }
      }
      return resultData
    },
    pdfExport () {
      const reportTitle = this.$t('fertilizerReport.ministry_allocation_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.allocationData, this, this.search, this.fertilizerNameList)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
